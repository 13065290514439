import { render } from "./Main.vue?vue&type=template&id=726a0bfa&scoped=true"
import script from "./Main.vue?vue&type=script&lang=js"
export * from "./Main.vue?vue&type=script&lang=js"

import "./Main.vue?vue&type=style&index=0&id=726a0bfa&lang=css"
import "./Main.vue?vue&type=style&index=1&id=726a0bfa&scoped=true&lang=css"
import "./Main.vue?vue&type=style&index=2&id=726a0bfa&lang=css"
script.render = render
script.__scopeId = "data-v-726a0bfa"

export default script