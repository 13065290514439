<template>
    <div class="item-chart">
        <div :class="'branchChart'+chartData.id"
            :style="{ width: '800px', height: '387px' }" style="margin: 30px auto;"></div>
    </div>
        
    
</template>

<script>
import { yearCert } from '../../../api/index'
export default {
    name: 'branch-chart',
    data() {
      return {
        yearBranchData: {
            name: [],
            count: []
        },
      }
    },
    props: {
        chartData: {
            type: Object
        }
    },
    mounted() {
        this.initPageData();
        
    },
    methods: {
        initPageData(){
            let apiData = {
              time_type: 'year',
              branch_id: this.chartData.id
          }
          yearCert({apiData}).then( res => {
              this.yearBranchData.name = [],
              this.yearBranchData.count = [],
              res.data.forEach(item => {
                  this.yearBranchData.name.push(item.name);
                  this.yearBranchData.count.push(item.count);
              });
              this.branchChart();
          })
        },
        branchChart(){
            //this.$root => app
            let roseCharts = document.getElementsByClassName("branchChart"+this.chartData.id);
            let branchChart = this.$root.echarts.init(roseCharts[0]);
            // 绘制图表
            branchChart.setOption({
                color: ['#008FD7'],
                title: { text: this.$t('data.credits.eCertIssueReport')},
                tooltip: {},
                xAxis: {
                    data: this.yearBranchData.name,
                },
                yAxis: {},
                series: [
                    {
                    name: "Amount",
                    type: "bar",
                    data: this.yearBranchData.count,
                    },
                ],
            });
        },
    }
}
</script>