<template>
    <div class="credits_main">

        <div class="page-header">
            <el-row>
                <el-col :span="12">
                    <div class="header-desc">
                        <p>{{ $t('data.credits.organizationName') }}: {{organization.name}}</p>
                        <p>{{ $t('data.credits.organizationId') }}: {{organization.id}}</p>
                    </div>
                </el-col>
                <el-col :span="12" align="right" v-if="$store.getters.isAdminAuth">
                    <router-link to='/credits/pricing'><el-button type="primary" icon="el-icon-my-meiyuan" round>{{ $t('data.public.topUp') }}</el-button></router-link>
                </el-col>
            </el-row>
        </div>

        <el-row :gutter="20">

            <el-col :span="12">
                <el-card shadow="always">
                    <div class="credits_card">
                        <div class="credits_remain mb-20">
                            <h4 align="center">{{ $t('data.credits.remainingCredits') }}: <strong>{{creditInfo.credit}}</strong></h4>
                            <p>1 {{ $t('data.credits.issue') }} = {{ issueCredit }} {{ $t('data.credits.credits') }}</p>
                        </div>
                        <el-row :gutter="20">
                            <el-col :span="12" align="left"><b>{{ $t('data.credits.accounts') }}: </b></el-col>
                            <el-col :span="12" align="right"><b>{{ $t('data.credits.assignCredits') }}</b></el-col>
                        </el-row>
                        <el-row :gutter="20" class="mt-20" v-for="(item, index) in creditInfo.branches" :key="index">
                            <el-col :span="12" align="left">{{item.name}} </el-col>
                            <el-col :span="12" align="right">
                                <el-input-number size="mini" v-model="item.credit" @change="changeNumber" :min="0" :max="creditInfo.credit + item.credit"></el-input-number>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20" class="mt-20" v-if="$store.getters.isAdminAuth">
                            <el-col :span="24" align="center">
                                <el-button type="primary" @click="creditConfirm">{{ $t('data.public.confirm') }}</el-button>
                            </el-col>
                        </el-row>
                    </div>
                </el-card>
            </el-col>

            <el-col :span="12">
                <el-card shadow="always">
                    <div class="credits_chart credits_card">
                        <h4>{{ $t('data.credits.issuesDisturbution') }}</h4>
                        <div class="credits_echarts">
                            <div id="remainingCredits" :style="{width: '100%', height: '310px'}" style="margin: 10px auto;"></div>
                        </div>
                       
                    </div>
                </el-card>
            </el-col>

            <el-col :span="24" class="mt-30">
                <el-tabs type="border-card">
                    <el-tab-pane v-for="(item,index) in creditInfo.branches" :key="index" :label="item.name">
                        <branch-chart :chartData="item"></branch-chart>
                    </el-tab-pane>
                </el-tabs>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import { getCredit,credisAssign,organizationInfo,getBase } from '../../api/index'
import branchChart from "./component/BranchChart"
import { ElMessage } from 'element-plus'
  export default {
    data() {
      return {
        creditInfo: [],
        issuesChart: [],
        colorList: ['#008FD7', '#224486', '#814ef1', '#21d2db'],
        organization:{},
        initCredit: 0,
        issueCredit: null
      }
    },
    components: {
        branchChart
    },
    computed:{
        // totalCredits: function(){
        //     let _this = this;
        //     let totalNumber = 0;
        //     if(this.creditInfo.branches){
        //         this.creditInfo.branches.forEach(function(item){
        //             totalNumber = totalNumber + item.credit;
        //         })
        //     }
        //     return totalNumber;
        // }
    },
    mounted() {
        this.ininPageData();
    },
    methods: {
        ininPageData(){
            let apiData = {};
            getCredit({apiData}).then( res => {
                let _this = this;
                this.creditInfo = res.data;
                this.issuesChart = [];
                res.data.branches.forEach(function(item,index){
                    let itemObj = {
                        value: item.credit,
                        name: item.name,
                        itemStyle: {
                            color: _this.colorList[index]
                        }
                    }
                    _this.issuesChart.push(itemObj);
                });
                this.remainingChart();
                this.initCredit = res.data.credit;
            });
            organizationInfo({apiData}).then( res => {
                this.organization = res.data;
            });
            getBase({}).then( res => {
                this.issueCredit = res.data.issue_credit;
            });
        },
        creditConfirm(){
            let apiData = {
                branches: this.creditInfo.branches
            };
            credisAssign({apiData}).then( res => {
                ElMessage.success({
                    message: 'Success',
                    type: 'success'
                });
            });
        },
        remainingChart(){
            let remainingCredits = this.$root.echarts.init(document.getElementById('remainingCredits'));
            // 绘制图表
            remainingCredits.setOption({
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    x:'center',
                    y:'bottom',
                },
                series: [
                    {
                        name: this.$t('data.credits.remainingCredits'),
                        type: 'pie',
                        radius: ['35%', '50%'],
                        label : {
                　　　　　　formatter: '{b}: {d}%'
                    　　},
                        data: this.issuesChart,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            });
        },
        changeNumber(val){
            let totalNumber = 0;
            let remainingNumber = 0;
            this.creditInfo.branches.forEach(function(item){
                totalNumber = totalNumber + item.credit;
            })
            remainingNumber = this.creditInfo.branch_credit - totalNumber;
            this.creditInfo.credit = this.initCredit + remainingNumber;
        }
    }
  };
</script>

<style>
.el-input-number .el-input .el-input__inner{
    vertical-align: top;
}
</style>

<style scoped>
.credits_remain p{
    height: 30px;
    line-height: 30px;
    color: #666666;
    font-size: 14px;
}
.credits_card{
    height: 360px;
}
.credits_chart{
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.credits_chart h4,.credits_remain h4{
    font-size: 24px;
    font-weight: 400;
}

/* .credits_chart .credits_chart_text{
    padding: 50px 0px;
}
.credits_chart .credits_chart_text p{
    height: 35px;
    line-height: 35px;
    font-size: 19px;
    color: #666;
} */
</style>
<style>
#remainingCredits > div{
    margin: 0px auto !important;
}
</style>